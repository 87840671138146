module controllers {
    export module customs {
        interface IAccountUpdateScope extends ng.IScope {
        }

        interface IAccountUpdateScopeParams extends ng.ui.IStateParamsService {
            accId: number;
        }

        export class accountUpdateCtrl {

            static $inject = [
                "$scope",
                "$rootScope",
                "generalService",
                "$q",
                "$anchorScroll",
                "$uibModal",
                "bsLoadingOverlayService",
                "$stateParams",
                "entityService",
                "$state",
                "$timeout",
                "$transitions",
                "documentRepositoryService",
                "accountService",
                "currencyService",
                "classificationValueService",
                "userAccountService",
                "countryService",
                "accountDetailService",
                "customsPartyService",
                "customsOfficeCodeService",
                "uiGridConstants",
                "accountPeriodService",
            ];

            dropdownsObject = {
            };

            accId: number;
            account: interfaces.customs.IAccount;

            //Declaration Country Variables
            declarationCountryId: number = 0;
            declarationCountryList: Array<interfaces.applicationcore.IDropdownModel>;
            declarationCountry: interfaces.applicationcore.IDropdownModel;
            //Customs Office Variables
            customsOfficeId: number = 0;
            customsOfficeList: Array<interfaces.applicationcore.IDropdownModel>;
            customsOffice: interfaces.applicationcore.IDropdownModel;

            //Currency Variables
            currencyId: number = 0;
            currencyList: Array<interfaces.applicationcore.IDropdownModel>;
            currency: interfaces.applicationcore.IDropdownModel;

            //Customs Party Variables
            customsPartyId: number = 0;
            customsPartyList: Array<interfaces.applicationcore.IDropdownModel>;
            customsParty: interfaces.applicationcore.IDropdownModel;

            //Account Period Variables
            accountPeriodId: number = 0;
            accountPeriodList: Array<interfaces.applicationcore.IDropdownModel>;
            accountPeriod: interfaces.applicationcore.IDropdownModel;

            breadCrumbDesc: string;
            selectedTab: number = 0;
            addendumHTML: string = "";

            isLoadingDetail: boolean = false;

            yesNoItems = [{
                id: null,
                label: 'Please Select',
            }, {
                id: true,
                label: 'Yes',
            }, {
                id: false,
                label: 'No',
            }];

            ownerEntityId: number;
            accountHook: any;

            constructor(
                private $scope: IAccountUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: IAccountUpdateScopeParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public accountService: services.customs.accountService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private userAccountService: interfaces.applicationcore.IUserAccountService,
                private countryService: interfaces.applicationcore.ICountryService,
                public accountDetailService: interfaces.customs.IAccountDetailService,
                private customsPartyService: interfaces.master.ICustomsPartyService,
                private customsOfficeCodeService: interfaces.tariff.ICustomsOfficeCodeService,
                private uiGridConstants: uiGrid.IUiGridConstants,
                public accountPeriodService: services.customs.accountPeriodService,
            ) {

                this.accId = $stateParams.accId;

                this.accountHook = $transitions.onSuccess({
                    to: 'auth.Account.Update',
                    from: 'auth.Account.Update.**'
                }, () => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'account.update'
                    }, () => {

                        return this.loadAccount();
                    });
                });

                var loadPromises: ng.IPromise<any>[] = [];
                loadPromises.push(this.loadAccount());
                loadPromises.push(this.loadCustomParties());
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'account.update'
                },
                    () => {                        
                        return $q.all(loadPromises);
                    });
            }


            loadAccount() {
                return this.accountService.getAccount(this.accId).query((data: interfaces.customs.IAccount) => {
                    this.account = data;
                    this.breadCrumbDesc = this.account.Code;
                    this.loadAccountPeriods();
                    this.$timeout(() => {
                        //Seperate AccountLineList From the Account and move it on its own
                        //this.accountDetailPeriodChange();
                        this.gvwDetails.data = this.account.AccountLineList;
                        this.gvwPeriodList.data = this.account.AccountPeriodList;
                        if (this.apiPeriodsList) {
                            this.apiPeriodsList.core.notifyDataChange(this.uiGridConstants.dataChange.ALL)
                        }
                        this.isLoadingDetail = false;
                    });
                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            apiACCDetailList: uiGrid.IGridApi;
            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiACCDetailList = gridApi;
                this.$timeout(() => {
                    this.gvwDetails.data = this.account.AccountLineList;
                });
            }
            //Build UIGrid
            gvwDetails: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ng-click="grid.appScope.accountUpdateCtrl.accLine(row.entity.Id,  grid.appScope.accountUpdateCtrl.account.DetailPeriodNumber ,grid.appScope.accountUpdateCtrl.account.Id)" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    visible: true
                },
                {
                    name: "EntityCode",
                    displayName: "Entity Code",
                    field: "EntityCode",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "PostingDate",
                    displayName: "Posting Date",
                    field: "PostingDate",
                    width: 150,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "Reference1",
                    displayName: "Reference 1",
                    field: "Reference1",
                    width: 130,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "Reference2",
                    displayName: "Reference 2",
                    field: "Reference2",
                    width: 200,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, 
                {
                    name: "Consignment",
                    displayName: "Consignment",
                    field: "Consignment",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, 
                {
                    name: "Type",
                    displayName: "Type",
                    field: "Type",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, 
                {
                    name: "TaxType",
                    displayName: "Tax Type",
                    field: "TaxType",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, 
                {
                    name: "Amount",
                    displayName: "Amount",
                    field: "Amount",
                    cellFilter: "number: 2",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }]
            };



            apiPeriodsList: uiGrid.IGridApi;
            selectedPL: uiGrid.IGridRow;

            registerPerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiPeriodsList = gridApi;

                this.apiPeriodsList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiPeriodsList.selection.selectRow(gridSelection.row.entity);                    
                });

                this.$timeout(() => {
                    this.gvwPeriodList.data = this.account.AccountPeriodList;
                });
            }
            gvwPeriodList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: false,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerPerGridApi(gridApi) },
                
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ng-click="grid.appScope.accountUpdateCtrl.periodLine(row.entity.Id, grid.appScope.accountUpdateCtrl.account.Id)" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    visible: true
                },
                {
                    name: "PeriodNumber",
                    displayName: "Period Number",
                    field: "PeriodNumber",
                    width: 150,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                }, 
                {
                    name: "DateFrom",
                    displayName: "Date From",
                    field: "DateFrom",
                    width: 150,
                    type: "date",
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="DateFrom"></gts-date>                                        
                        </div>`,
                }, 
                {
                    name: "DateTo",
                    displayName: "Date To",
                    field: "DateTo",
                    width: 150,
                    type: "date",
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="DateTo"></gts-date>                                        
                        </div>`,
                }]
            };

            periodRemoveClick() {
                var selectedRows = <Array<interfaces.customs.IAccountPeriodDisplay>>this.apiPeriodsList.selection.getSelectedRows();

                if (selectedRows && selectedRows.length <= 0) {
                    return;
                }

                if (!selectedRows[0].Id || selectedRows[0].Id === 0) {
                    this.apiPeriodsList.rowEdit.setRowsClean(selectedRows);
                    (<Array<any>>(this.gvwPeriodList.data)).splice((<Array<any>>(this.gvwPeriodList.data)).lastIndexOf(selectedRows[0]), 1);
                    return;
                }

                this.generalService.displayConfirmationBox("Confirm?", "Are you sure you want to remove the Period Line?").then((result: boolean) => {
                    if (result) {
                        this.removePeriodLine(selectedRows[0].Id);
                    }
                });
            }

            removePeriodLine(Id: number) {
                this.accountService.deletePeriod(Id).delete((result) => {
                    this.generalService.displayMessageHandler(result);

                    this.loadAccount();

                }, (failureMessage) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                });
            }
            

            periodLine_click() {
                this.periodLine(0, this.account.Id, this.account.LockdownType)
            }
            periodLine(periodId: number, accountId: number, LockdownType: number) {
                this.$uibModal.open({
                    animation: true,
                    size: "lg",
                    template: `
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">Customs Account Posting Period</h3>
                            </div>
                            <div bs-loading-overlay bs-loading-overlay-reference-id="perlines.update">
                                <form name="accLinesUpdate">
                                    <gts-message-display messages="Messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="gts-padding">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <req></req>
                                                            <label>Period Number</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                <input ng-disabled="periodLineCtrl.LockdownType>=4" class="form-control" type="text" ng-model="periodLineCtrl.accountPeriodLine.PeriodNumber" name="PeriodNumber" aria-label="Period Number" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <req></req>
                                                            <label>Date From</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                    <gts-date ng-disabled="periodLineCtrl.LockdownType>=4" format="yyyy/MM/dd" ng-model="periodLineCtrl.accountPeriodLine.DateFrom" name="DateFrom">
                                                                    </gts-date>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <req></req>
                                                            <label>Date To</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                    <gts-date  ng-disabled="periodLineCtrl.LockdownType>=4" format="yyyy/MM/dd" ng-model="periodLineCtrl.accountPeriodLine.DateTo" name="DateTo">
                                                                    </gts-date>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-primary" ng-disabled="periodLineCtrl.LockdownType>=4" type="button" ng-click="periodLineCtrl.save()">Submit</button>
                                            <button class="btn btn-primary" type="button" ng-click="periodLineCtrl.cancel()">Close</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        `,
                    controller: class periodLineCtrl {
                        messages: interfaces.applicationcore.IMessage[];
                        accountPeriodLine: interfaces.customs.IAccountPeriod;
                        constructor(
                            $scope,
                            private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            public accountPeriodService: services.customs.accountPeriodService,
                            private bsLoadingOverlayService,
                            private $q: ng.IQService,
                            private LockdownType : number
                        ) {

                            var loadPromises: ng.IPromise<any>[] = [];
                            loadPromises.push(this.loadAccountPeriodLine(periodId, accountId));

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'perlines.update'
                            },
                                () => {
                                    return $q.all(loadPromises);
                                });
                        }

                        cancel() {
                            this.$uibModalInstance.dismiss();
                        }


                        SaveAccount(): ng.IPromise<boolean> {
                            var deferre = this.$q.defer<boolean>();

                            this.accountPeriodService.save().save(this.accountPeriodLine, (data: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(data);

                                deferre.resolve(true);
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                deferre.resolve(false);
                            });

                            return deferre.promise;
                        };

                        save() {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'perlines.update'
                            },
                                () => {
                                    return this.SaveAccount().then((data: boolean) => {
                                        if (data) {
                                            this.$uibModalInstance.close(true);
                                        }
                                    });
                                });

                        }
                        loadAccountPeriodLine(periodId: number, accountId: number) {
                            return this.accountPeriodService.getAccountPeriodDetail(periodId, accountId).query((data: interfaces.customs.IAccountPeriod) => {
                                this.accountPeriodLine = data;
                            }
                                , (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                }).$promise;
                        }
                    },
                    controllerAs: "periodLineCtrl",
                    resolve: {
                        LockdownType: this.account.LockdownType
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadAccount();
                    }
                });
            }

            accLine_click() {
                this.accLine(0, this.account.DetailPeriodNumber, this.account.Id)
            }
            accLine(accountLineId: number, accountPeriodId: number, accountId: number)
            {
                this.$uibModal.open({
                    animation: true,
                    size: "lg",
                    template: `
                            <div class="modal-header">
                              <h3 class="modal-title" id="modal-title">Customs Account Detail</h3>
                                </div>
                                <div bs-loading-overlay bs-loading-overlay-reference-id="acclines.update">
                                    <form name="accLinesUpdate">
                                        <gts-message-display messages="Messages"></gts-message-display>
                                        <div class="modal-body" id="modal-body">
                                            <div class="gts-padding">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="row vertical-align">
                                                            <div class="col-md-4">
                                                                <req></req>
                                                                <label>Entity</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <p class="input-group-sm">
                                                                    <gts-dropdown  ng-disabled="accLineCtrl.LockdownType>=4" name="Entity" ng-model="accLineCtrl.accountLine.Entity" load-data="accLineCtrl.loadEntities(searchText)" required="true"></gts-dropdown>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row vertical-align">
                                                            <div class="col-md-4">
                                                                <req></req>
                                                                <label>Posting Date</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <p class="input-group-sm">
                                                                    <gts-date  ng-disabled="accLineCtrl.LockdownType>=4" format="yyyy/MM/dd" ng-model="accLineCtrl.accountLine.PostingDate" name="PostingDate">
                                                                    </gts-date>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row vertical-align">
                                                            <div class="col-md-4">
                                                                <req></req>
                                                                <label>Reference 1</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <p class="input-group-sm has-feedback"  ng-class="{'has-error': !accLineCtrl.accountLine.Reference1}">
                                                                    <input  ng-disabled="accLineCtrl.LockdownType>=4" class="form-control" type="text" ng-model="accLineCtrl.accountLine.Reference1" name="Reference1" aria-label="Reference 1" required="true" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row vertical-align">
                                                            <div class="col-md-4">
                                                                <opt></opt>
                                                                <label>Reference 2</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <p class="input-group-sm">
                                                                    <input   ng-disabled="accLineCtrl.LockdownType>=4" class="form-control" type="text" ng-model="accLineCtrl.accountLine.Reference2" name="Reference2" aria-label="Reference 2" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row vertical-align">
                                                            <div class="col-md-4">
                                                                <req></req>
                                                                <label>Type</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <p class="input-group-sm">
                                                                    <gts-dropdown  ng-disabled="accLineCtrl.LockdownType>=4" name="Type" ng-model="accLineCtrl.accountLine.Type" load-data="accLineCtrl.loadType()" required="true"></gts-dropdown>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row vertical-align">
                                                            <div class="col-md-4">
                                                                <req></req>
                                                                <label>Amount</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <p class="input-group-sm">
                                                                    <input  ng-disabled="accLineCtrl.LockdownType>=4" class="form-control" type="text" step="0.01" custom-format="number" ng-model="accLineCtrl.accountLine.Amount" name="Amount" aria-label="SuplierValue" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button class="btn btn-primary"  ng-disabled="accLineCtrl.LockdownType>=4" type="button" ng-click="accLineCtrl.save()">Submit</button>
                                                <button class="btn btn-primary" type="button" ng-click="accLineCtrl.cancel()">Close</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                        `,
                    controller: class accLineCtrl {
                        messages: interfaces.applicationcore.IMessage[];
                        accountLine: interfaces.customs.IAccountLine;                        
                        constructor(
                            $scope,
                            private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            public accountDetailService: services.customs.accountDetailService,
                            private bsLoadingOverlayService,
                            private $q: ng.IQService,
                            private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private LockdownType : number
                        ) {                          

                            var loadPromises: ng.IPromise<any>[] = [];
                            loadPromises.push(this.loadAccountLine(accountLineId, accountPeriodId, accountId));
                            
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'acclines.update'
                            },
                                () => {
                                    return $q.all(loadPromises);
                                });                           
                        }

                        cancel() {
                            this.$uibModalInstance.dismiss();
                        }

                        loadType() {
                            return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                                classificationId: Enum.ClassificationEnum.CustomsAccountDetailType
                            }, () => {
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        }
                        loadEntities(searchText: string) {
                            return this.entityService.getDropdownList(searchText).query(
                                () => {
                                }, (failureData) => {
                                }).$promise;
                        }
                        SaveAccount(): ng.IPromise<boolean> {
                            var deferre = this.$q.defer<boolean>();

                            this.accountDetailService.save().save(this.accountLine, (data: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(data);
                                deferre.resolve(true);
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                deferre.resolve(false);
                            });

                            return deferre.promise;
                        };

                        save() {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'account.update'
                            },
                                () => {
                                    return this.SaveAccount().then((data: boolean) => {
                                        if (data) {
                                            this.$uibModalInstance.close(true);
                                        }
                                    });
                                });
                        }

                        loadAccountLine(accountLineId: number, accountPeriodId: number, accountId: number) {
                            return this.accountDetailService.getAccountLineDetail(accountLineId, accountPeriodId, accountId).query((data: interfaces.customs.IAccountLine) => {
                                this.accountLine = data;
                            }
                            , (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        }                        

                    },
                    controllerAs: "accLineCtrl",
                    resolve: {
                        LockdownType: this.account.LockdownType
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadAccount();
                    }
                });
            }
            
            loadAccountLines() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'account.update'
                }, () => {
                        return this.accountDetailService.getAccountLines().query({
                            accountId: this.account.Id,
                            periodId: this.account.DetailPeriodNumber,
                        }, (result: Array<interfaces.customs.IAccountLineDisplay>) => {

                                this.account.AccountLineList = result;
                                this.gvwDetails.data = result;
                                this.account.DetailCurrentPeriod = result.reduce((sum: number, b: interfaces.customs.IAccountLineDisplay) => sum+ b.Amount, 0).toString();

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    deferred.resolve(true);

                }, (failureData) => {

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            loadDeclarationCountries() {
                return this.countryService.getDropdownList().query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }
            declarationCountryChange() {
                this.account.CustomsOfficeCode = undefined;
            }
            loadCustomsOffices(searchText: string) {
                if (this.account.DeclarationCountry.Id > 0) {
                    return this.customsOfficeCodeService.getForDropdown(searchText, this.account.DeclarationCountry.Id).query().$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }
            
            loadAccountPeriods() {
                return this.accountPeriodService.getAccountPeriodsForDropdown(this.account.Id).query((successList) => {
                    this.accountPeriodList = successList;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadCurrencies() {
                return this.currencyService.getCurrencyDropdownList().query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadCustomParties() {
                return this.customsPartyService.getList().query((successList) => {
                    this.customsPartyList = successList;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            
            loadUsers(searchText: string) {
                if (this.account) {
                    return this.userAccountService.getDropdownList(searchText, this.account.EntityId).query(
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            SaveAccount(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                this.accountService.save().save(this.account, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    this.accId = Number(data.ID);

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'account.update'
                },
                    () => {
                        return this.SaveAccount().then((data: boolean) => {
                            if (data) {
                                this.loadAccount();
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.Account.Update", { accId: this.accId });
                            }
                        });
                    });

            }
            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'account.update'
                }, () => {
                        return this.SaveAccount().then(() => {
                        this.Close();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        this.$anchorScroll("topAnchor");
                    });
                });
            }
            Close() {
                this.$state.go("^");
            }
        };

        angular.module("app").controller("accountUpdateCtrl", controllers.customs.accountUpdateCtrl);
    }
}
